/* .text-responsive-small {
  font-size: calc(0.5% + 1vw + 1vh) !important;
} */

@media (min-width: 768px) {
  .text-responsive-small {
    font-size: calc(1vw) !important;
  }
}

@media (min-width: 992px) {
  .text-responsive-small {
    font-size: calc(1vw) !important;
  }
}

@media (min-width: 1200px) {
  .text-responsive-small {
    font-size: 1rem !important;
  }
}
