/* Chrome, Safari, Edge, Opera */
input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].no-spin {
  -moz-appearance: textfield;
}

.bg-gradient-home {
  background: linear-gradient(87deg, #ffffff 0, #ebeced 100%) !important;
}
