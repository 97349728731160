.big-responsive {
  font-size: calc(1% + 1vw + 1vh) !important;
}
.medium-responsive {
  font-size: calc(0.5% + 1vw + 1vh) !important;
}

.coverageType {
  font-size: calc(50% + 1vw + 1vh) !important;
}

@media (min-width: 992px) {
  .big-responsive {
    font-size: calc(1vw) !important;
  }
  .medium-responsive {
    font-size: calc(1vw * 0.8) !important;
  }

  .coverageType {
    font-size: calc(1vw + 1vh) !important;
  }
}
